
import './App.css';
import Pages from './components/UI/pages/Pages';

function App() {
  return (
    <>
   <Pages />
    </>
         
  );
}

export default App;
