import React from "react";
import './exercises.css';
import lunges from "../../../assets/img/muscle.png";
import stress from "../../../assets/img/stress.png";
import fit from "../../../assets/img/fit.png";
import healthy from "../../../assets/img/healthy.png";


const Exercises = () => {
    return (
        <section>
            <div className="container exercise__container">
                <div className="exercise__top">
                    <h2 className="section__title">
                        Benefits of <span className="highlights">Sport</span>
                    </h2>
                    {/* <p>Lorem ipsum dolor. Provident quae quo, <br /> Fuga omnis nesciunt, dicta iste eius explicabo?</p> */}
                </div>
                {/* ========Exercise list */}
                <div className="exercise__wrapper">
                    <div className="exercise__item">
                        <span className="exercise__icon">
                            <img src={lunges} alt="" />
                        </span>
                        <div className="exercise__content">
                            <h3>More Power</h3>
                            <p>Boost your vitality with invigorating activities and habits that empower your body and mind, providing a renewed sense of strength and energy.</p>

                        </div>
                    </div>
                    <div className="exercise__item">
                        <span className="exercise__icon">
                            <img src={healthy} alt="" />
                        </span>
                        <div className="exercise__content">
                            <h3>Healthy Life</h3>
                            <p>Embrace a lifestyle that nurtures your well-being, incorporating wholesome choices that contribute to a balanced, vibrant, and fulfilling existence.</p>

                        </div>
                    </div>
                    <div className="exercise__item">
                        <span className="exercise__icon">
                            <img src={fit} alt="" />
                        </span>
                        <div className="exercise__content">
                            <h3>Healthy Body</h3>
                            <p>Cultivate a strong and resilient physique by prioritizing activities and practices that support overall health, ensuring your body functions at its best.</p>

                        </div>
                    </div>
                    <div className="exercise__item">
                        <span className="exercise__icon">
                            <img src={stress} alt="" />
                        </span>
                        <div className="exercise__content">
                            <h3>Stress Reduction</h3>
                            <p>Unwind and find tranquility amidst life's demands, as you explore strategies to alleviate stress and promote a calm, centered state of mind.</p>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Exercises;