import React, { useState } from "react";
import './navbar.css';
import logo from '../../assets/img/dumble.png';
import { Link } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import { BsFullscreenExit } from "react-icons/bs";

const nav = [
  { path: '/', text: 'Home' },
  { path: '/coaches', text: 'Coaches' },
  { path: '/pricing', text: 'Pricing' },
  { path: '/about', text: 'About' },
  { path: '/contact', text: 'Contact-us' },
];

const NavBar = () => {
  const [navList, setNavList] = useState(false);

  const handleLinkClick = () => {
    setNavList(false);
  };
  

  return (
    <header>
      <div className="container">
        <div className="nav__wrapper">
          <div className='cont flex'>
            <div className="logo">
              <div className="logo__img">
                <img src={logo} alt="Fitbody Logo" />
              </div>
              <h2> <Link to="/">Fitbody</Link></h2>
            </div>
            <div className="links">
           
              <ul className={navList ? "small" : "flex"}>
                {nav.map((list, index) => (
                  <li key={index}>
                    <Link to={list.path} onClick={handleLinkClick}>{list.text}</Link>
                  </li>
                ))}
              </ul>
              </div>
            {/* </div> */}

            <div className="nav__right">
            <Link to="/signUp">
              <button className="register__btn">Get Started</button>
              </Link>
            </div>

            <div className='toggle'>
              <button onClick={() => setNavList(!navList)}>
                {navList ? <BsFullscreenExit /> : <HiMenu />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
