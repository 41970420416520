import React from "react";
import './coach.css';
import bill from "../../../assets/img/BILL.jpg";
import tate from "../../../assets/img/Tatex.jpg";
import star from "../../../assets/img/star.jpg";
import does from "../../../assets/img/Does.jpg";

const Coach = () => {
    return (
        <section>
            <div className="container coach__container">
                <div className="coach__top">
                    <h2 className="section__title">
                        Our <span className="highlights">Coaches</span>
                    </h2>
                </div>
                {/* ========coach list */}
                <div className="coach__wrapper">
                    <div className="coach__item">
                        <span className="coach__icon">
                            <img src={star} alt="" />
                        </span>
                        <div className="coach__content">
                            <h3>Valery Golbstchov</h3>
                        </div>
                    </div>
                    <div className="coach__item">
                        <span className="coach__icon">
                            <img src={bill} alt="" />
                        </span>
                        <div className="coach__content">
                            <h3>Sergey Sovilor</h3>
                        </div>
                    </div>
                    <div className="coach__item">
                        <span className="coach__icon">
                            <img src={tate} alt="" />
                        </span>
                        <div className="coach__content">
                            <h3>Andrew Tate</h3>
                        </div>
                    </div>
                    <div className="coach__item">
                        <span className="coach__icon">
                            <img src={does} alt="" />
                        </span>
                        <div className="coach__content">
                            <h3>Kal Olig</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Coach;
