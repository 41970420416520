import React from "react";

import "./contact.css"

const Contact = () => {
    return <>
    <div className="contact">
     <div className='container'>
          <form className='shadow'>
            <h4>Fillup The Form</h4> <br />
           
              <input type='text' placeholder='Name' />
              
              <input type='eamil' placeholder='Email' />
              <input type='tel' placeholder='Phone' />
              <input type='text' placeholder='Subject' />
             
                        
            <textarea cols='30' rows='5' placeholder='Details'></textarea>
            <button>Submit Request</button>
          </form>
        </div>
    
        </div>

    </>
}
 
export default Contact;