import React from "react";
import './start.css';
import trainerImg from "../../../assets/img/hero_image.png";
import { Link } from "react-router-dom";

const Start = () => {
    return (
        <section>
            <div className="container">
                <div className="start__wraper">
                    <div className="box-01">
                        <div className="box-02">
                            <div className="box-03">
                                <div className="start__img"><img src={trainerImg} alt="" />
                                </div></div></div></div>

                    <div className="start__content">
                        <h2 className="section__title">Ready  to make a <span className="highlights">change? </span> </h2>
                        <p>Unlock your fitness potential at FitBody. Explore diverse classes, top-notch facilities, and a supportive community for a healthier you.</p>
                        <Link to="/signUp">
                        <button className="register__btn">Get Started</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Start;