import React from "react";
import './pricing.css';
import { FaRegCheckCircle } from "react-icons/fa";
// import { MdOutlineRadioButtonUnchecked} from "react-icons/md";
const data = [
  {
    ico: <FaRegCheckCircle />,
    par: 'Unlimited access to the gym'
  },
  {
    ico: <FaRegCheckCircle />,
    par: 'Customer support'
  },
  {
    ico: <FaRegCheckCircle />,
    par: 'Personal trainer'
  },
  {
    ico: <FaRegCheckCircle />,
    par: 'Standard options'
  }

]
const Pricing = () => {
  return (<>
    <section>
      <div className="container">
        <div className="price_ele">
        <div className="pricing__top">
          <h2 className="section__title"><span className="highlights">Pricing</span> Plan</h2>
          {/* <p >Lorem ipsum dolor sit,<br /> nesciunt labore optio.</p> */}
        </div>

        {/* pricing wrapper */}
        <div className="pricing__wrapper">
          <div className="pricing__item">
            <div className="pricing__card-top">
              <h2 className="section__title">Regular Member</h2>
              <h2 className="pricing section__title">50$ /Month</h2>
            </div>
            <div className="services">
              <ul>
                {
                  data.map(item => (

                    <li className="price_item"><span>{item.ico}</span>{item.par}</li>
                  ))
                }
              </ul>
              <button className="register__btn">Join Now</button>
            </div>


          </div>

          {/*  here  */}
          <div className="pricing__item pricing__item-02">
            <div className="pricing__card-top">
              <h2 className="section__title">Premium Member</h2>
              <h2 className="pricing section__title">$70 /month</h2>
            </div>

            <div className="services">
              <ul>
                {
                  data.map(item => (

                    <li className="price_item"><span>{item.ico}</span>{item.par}</li>
                  ))
                }
              </ul>
              <button className="register__btn">Join Now</button>
            </div>
          </div>

          {/* here3 */}
          <div className="pricing__item" >
            <div className="pricing__card-top">
              <h2 className="section__title">Standard Member</h2>
              <h2 className="pricing section__title">$100 /month</h2>
            </div>

            <div className="services">
              <ul>
                {
                  data.map(item => (

                    <li className="price_item"><span>{item.ico}</span>{item.par}</li>
                  ))
                }
              </ul>
              <button className="register__btn">Join Now</button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  </>);
}

export default Pricing;