import React from "react";
import './style.css';

import logo from '../../../assets/img/dumble.png';

const SignUp = () => {
  return (
    <>
    <div className="signup">
      {/* <div className="circle"></div> */}
      <div className="card">
        <div className="logo">
          {/* <i className="bx bxs-bank"></i> */}
          <img src={logo} alt="Fitbody Logo" />
        </div>
        <h2>Create Account</h2>
        <form className="form">
          <input type="text" placeholder="First Name" />
          <input type="text" placeholder="Last Name" />
          <input type="text" placeholder="Email" />
          <input type="text" placeholder="Password" />
          <input type="text" placeholder="Confirm Password" />
          <button type="submit">SIGN UP</button>
        </form>
        <footer>
          Existing users, sign in:
          <a href="/">SIGN IN</a>
        </footer>
      </div>
      </div>
    </>
  );
};

export default SignUp;
