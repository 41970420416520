import React from "react";

import Home from "./Home";
import Pricing from "../Pricing/Pricing";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    // Link,
  } from "react-router-dom";
import Footer from "../Footer/Footer";
import ErrorPage from "../ErrorPage/ErrorPage";
import About from "../About/About";
import Contact from "../Contact/Contact";
import NavBar from "../../NavBar/NavBar";
import Coach from "../Coach/Coach";
import SignUp from "../Sign_Up/SignUP";

const Pages = () => {
    return <>
    

      <Router>
     
      <NavBar />
      <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/pricing' element={<Pricing/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/coaches' element={<Coach/>} />
      <Route path='/signUp' element={<SignUp/>} />
      <Route path='*' element={<ErrorPage/>} />
     
    
      </Routes>
      <Footer/>

      </Router>

     
    </>
    
}
 
export default Pages;