import React from "react";
import './hero.css'
import heroImg from "../../../assets/img/hero_image.png";
import { Link } from "react-router-dom";
// import dumbleIcon from "../../../assets/img/dumble.png";
// import heart from "../../../assets/img/Heart.png";
// import weights from "../../../assets/img/weights.png";
// import muscle from "../../../assets/img/muscle.png";

function openYouTube(url) {
    window.open(url, "_blank"); // Opens YouTube in a new tab
  }
  
const Hero = () => {
    return (
        <section>
            <div className="container">
                <div className="hero__wrapper">
                    {/* Hero content */}
                    <div className="hero__content">
                        <h2 className="section__title" data-aos="fade-up" data-aos-duration="1500"> <span className="highlights">Sport  </span> unlocks the key to a healthy <br /> <span className="highlights">Healthy Lifestyle</span></h2>
                        <p>Choose from hundreds of workouts,  healthy recipes, <br /> relaxing meditations, and expert articles,  <br />for a whole body and mind approach to feeling great.</p>
                        <div className="hero__btns">
                        <Link to="/signUp">
                            <button className="register__btn">Get Started</button>
                            </Link>
                            <button className="register__btn"  onClick={() => openYouTube("https://www.youtube.com")}>
                                Watch Video
                            </button>
                        </div>
                    </div>
                    {/* Hero Img  */}
                    <div className="hero__img">
                        <div className="hero__img-wrapper">
                            <div className="box-01">
                                <div className="box-02">
                                    <div className="box-03">
                                        <div className="box__img">
                                            <img src={heroImg} alt="" />
                                            {/* <div className="heart sty" >
                                                <img className="imageWH" src={heart} alt="" />
                                            </div>
                                            <div className="dumble__icon sty" >
                                                <img className="imageWH" src={dumbleIcon} alt="" />
                                            </div>
                                            <div className="muscle sty" >
                                                <img className="imageWH" src={muscle} alt="" />
                                            </div>
                                            <div className="wheight sty" >
                                                <img className="imageWH" src={weights} alt="" />

                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;