import React from "react";
import './about.css';

const About = () => {
    return <>
    <div className="cont_extr">
    <div className="container">
       <div className="about_wrapper">
                    <div className="about_item">
                  
                             <h1>ABOUT FITBODY </h1> 
                           <p>
Our goal is to make health and fitness attainable, affordable and approachable.
Created to help you live a better, happier, healthier life.
We believe fitness should be accessible to everyone, everywhere, regardless of income level or access to a gym. That's why we offer hundreds of free, full-length workout videos, the most affordable and effective workout programs on the web, meal plans, and helpful health, nutrition and fitness information.
</p>
                   </div>
                    

                </div>
                </div>
</div>
    </>
}
 
export default About;