import React from "react"
import { Link } from 'react-router-dom'
import './ErrorPage.css'
const ErrorPage = () => {
    return (  
    <div className="container">
      <div className="containerErr">
      <p>404</p>
      <h1 >Page not found</h1>
      <p >Sorry, we couldn’t find the page you’re looking for.</p>
      <div className="lik">
      <Link to='/' >
          Home Page<span aria-hidden="true">&rarr;</span>
        </Link>
        </div>
    </div>
    </div>

  );
}
 
export default ErrorPage;