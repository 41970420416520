 import React from "react";
import Hero from "../Hero/Hero";
import Exercises from "../Exercises/Exercises";
import Start from "../Start/Start";
import Pricing from "../Pricing/Pricing";
import Coach from "../Coach/Coach";
const Home = () => {
    return <>
    <Hero/>
    <Exercises/>
    <Start />
    <Coach />
    <Pricing />
    </>
}
 
export default Home;